import React from 'react'
import ChangePassword from '../../components/changePassword/ChangePassword'
import Seo from "../../components/seo";

const set_password = (props) => {

    return (
        <>
            <Seo title="Set Password" />
            <ChangePassword token={props.params.token} />
        </>
    )
}

export default set_password